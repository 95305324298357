import {computed} from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [
    {
    slug: 'infrastructure-components',
    path: '/infrastructure-components',
    name: 'InfrastructureComponents',
    label: t('menuItems.infrastructureComponents.linkLabel'),
    /*children: [
        {
        path: '/reports/overview',
        name: 'ListReports',
        label: this.$t('menuItems.reports.children.overview.linkLabel')
        }
    ]*/
    },
    {
    slug: 'images',
    path: '/assets/images',
    name: 'Images',
    label: t('menuItems.images.linkLabel'),
    },
    {
    slug: 'fonts',
    path: '/assets/fonts',
    name: 'Fonts',
    label: t('menuItems.fonts.linkLabel'),
    }
]);

export {
  menuItems
}