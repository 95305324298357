const InfrastructureComponents = () =>  import(/* webpackChunkName: "InfrastructureComponents" */ "./pages/InfrastructureComponents");
const InfrastructureComponentList = () => import(/* webpackChunkName: "InfrastructureComponentList" */ "./pages/InfrastructureComponents/InfrastructureComponentList");
const InfrastructureComponentDetails = () => import(/* webpackChunkName: "InfrastructureComponentDetails" */ "./pages/InfrastructureComponents/InfrastructureComponentDetails");

const Assets = () => import( /* webpackChunkName: "Assets" */ "./pages/Assets");
const AssetImageList = () => import( /* webpackChunkName: "AssetImageList" */ "./pages/Assets/AssetImageList");
const AssetImageDetails = () => import(/* webpackChunkName: "AssetImageDetails" */ "./pages/Assets/AssetImageDetails");
const AssetFontList = () => import(/* webpackChunkName: "AssetFontList" */ "./pages/Assets/AssetFontList");

const pages = [
    {
    path: '/',
    redirect: '/infrastructure-components'
  },
  {
    path: '/infrastructure-components',
    name: 'InfrastructureComponents',
    component: InfrastructureComponents,
    redirect: '/infrastructure-components/list',
    children: [
      {
        path: '/infrastructure-components/list',
        name: 'InfrastructureComponentList',
        component: InfrastructureComponentList
      },
      {
        path: '/infrastructure-components/:id',
        name: 'InfrastructureComponentDetails',
        component: InfrastructureComponentDetails,
        props: true
      }
    ]
  },
  // {
  //   path: '/assets',
  //   name: 'HubSystemAssets',
  //   component: HubSystemAssets,
    // redirect: '/assets/images',
  //   children: [
  //     {
  //       path: '/assets/images',
  //       component: ImageAssets
  //     },
  //     {
  //       path: '/assets/font',
  //       component: FontAssets
  //     }
  //   ]
  // },

  {
    path: '/assets',
    name: 'Assets',
    component: Assets,
    redirect: '/assets/images',
    children: [
      {
        path: '/assets/images',
        name: 'AssetImageList',
        component: AssetImageList
      },
      {
        path: '/assets/images/:id',
        name: 'AssetImageDetails',
        component: AssetImageDetails,
        props: true
      },
      {
        path: '/assets/fonts',
        name: 'AssetFontList',
        component: AssetFontList
      },
    ]
  },
];

    
export {
    pages
}